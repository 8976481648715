import React from 'react'
import SliceZone from '../components/misc/PrismicSliceZone'
import Layout from '../components/misc/Layout'
import { graphql } from 'gatsby'

export const query = graphql`
  query ProductCategoryQuery($uid: String) {
    prismic {
      allPagina_de_productos(uid: $uid) {
        edges {
          node {
            titulo_del_documento
            descripcion_del_documento
            body {
              ... on PRISMIC_Pagina_de_productoBodyTarjeta_de_categoria {
                type
                label
                primary {
                  titulo_de_tarjeta_categoria
                  imagen
                  caracteristicas
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTitulo_de_relacionados {
                type
                label
                primary {
                  titulo
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyImagen {
                type
                label
                primary {
                  imagen
                  imagenSharp {
                    id
                  }
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTexto_con_enumeracion {
                type
                label
                primary {
                  texto_enumeracion
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTexto_basico {
                type
                label
                fields {
                  texto_descriptivo
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTitulo_con_icono {
                type
                label
                primary {
                  titulo
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTarjeta_de_producto {
                type
                label
                fields {
                  titulo
                  imagen
                  enlace_afiliados {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTitulo_y_descripcion_standar {
                type
                label
                primary {
                  titulo
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTexto_enriquecido {
                type
                label
                fields {
                  contenido_texto_enriquecido
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyTitulo_banda {
                type
                label
                primary {
                  titulo
                }
              }
              ... on PRISMIC_Pagina_de_productoBodyGrupo_de_categorias {
                type
                label
                fields {
                  categoria {
                    _linkType
                    ... on PRISMIC_Pagina_de_producto {
                      titulo_del_documento
                      subtitulo_del_documento
                      imagen_destacada
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const checkExistDataByPrismic = data => {
  return data && data.prismic && !!data.prismic.allPagina_de_productos.edges[0]
}

const ProductCategory = ({ data }) => {
  if (!checkExistDataByPrismic(data)) return null
  const [prismicContent] = data.prismic.allPagina_de_productos.edges
  const { node: document } = prismicContent
  return (
    <Layout>
      <SliceZone sliceZone={document.body} />
    </Layout>
  )
}

export default ProductCategory
